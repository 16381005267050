import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import ArticlesComponent from "../components/articles";
// import "../assets/css/main.scss";

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout seo={data.strapiHomepage.seo}>
      <ArticlesComponent articles={data.allStrapiArticle.edges} />
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
    allStrapiArticle(filter: {status: {eq: "published"}}, sort: {order: ASC, fields: order}) {
      edges {
        node {
          strapiId
          slug
          title
          description
          image {
            childImageSharp {
              fluid (sizes: "576px, 992px, 1680px", srcSetBreakpoints: [576, 992, 1680]) {
                srcSet
                srcSetWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
