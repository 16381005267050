import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

const Card = ({ article }) => {
  return (
    <Link to={`/${article.node.slug}`} className="mr-item">
      <div className="mr-item-image">
        <Img
          fluid={article.node.image.childImageSharp.fluid}
          alt={article.node.title}
        />
      </div>
      <div className="mr-mask-info">
        <h3>{article.node.title}</h3>
        <p>{article.node.description || ''}</p>
        <span className="btn-mod btn-mod-dark">ver más</span>
      </div>
    </Link>
  );
};

export default Card;
